.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.title-bar {
  color: white;
  background-color: black;
  height: 200px;
  .title-text {
    text-align: center;
    height: 85%;
    .headline {
      color:white;
      font-size: 6vw;
      font-weight: 900;
    }
    .sitename {
      color: white;
      font-size: 2vw;
    }
  }
}

.navbar {
  color: white;
  font-size: medium;
  font-weight: 500;
  font-family: "Arial, Helvetica, sans-serif";
  .link-grid-item {
    padding: 0px 30px 0px 30px;
    text-align: center;
  }
  a { color: white; :visited { color: white;}
      font-size: large;
      font-weight: 800;
      font-family: Arial, Helvetica, sans-serif;
      text-decoration: none;
    }
  .active {
    font-size: larger;
    text-decoration: underline;
  }
}

.content {
  margin-left: 10px;
  margin-right: 10px;
  min-height: 60vh;
}